import React from "react";
import Banner from "../components/Banner";
import WhatServiceDo from "../components/service/WhatServiceDo";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `<span class="h1-span">Product </span><span class="h1-span">Development Company</span>`,
    content:
      "Octet provides product development expertise to help simplify complex products and deliver intuitive user experiences. ",
  };
  const whatServiceContent = {
    title: "Revolutionize Vision with Product Development Agency",
    content: [
      "Product development experts assist you throughout the production process, from when you have a concept to its development.",
      "They work closely with you to understand your business, project requirements, target audience, and other factors before providing product development expertise, such as market research, prototyping, design, testing, and development.",
      "Product Developers simplify complex products and expedite processes by drawing on their extensive expertise in diverse sectors.",
      "Similarly, as a top product development company, we leverage our design and development skills to support you from product ideation to launch.",
    ],
    img: ["what-service-image-product-dev.webp"],
    imageTitle: "Product Development Agency",
    imageAlt: "",
  };

  const commonContent = {
    title: "Industry Specific Product Development Services",
    para: "Product Development Services are critical to the success of any organization trying to bring creative ideas to life, diversify product offerings, optimize existing products, improve user experience, and more.",
    img: ["who-needs-product-dev.webp"],
    imageTitle: "Product Development Services",
    imageAlt: "",
    content: [
      {
        title: "Cloud Service Providers",
        text: "Companies that seek to develop and improve their product offerings to improve user experience, increase their portfolio, and remain competitive.",
      },
      {
        title: "Consumer Companies",
        text: "Companies who aspire to satisfy changing customer expectations by providing exceptional digital experiences and customer satisfaction.",
      },
      {
        title: "HealthTech Companies",
        text: "Companies striving to develop cutting-edge solutions to boost productivity and provide a market-impacting product.",
      },
      {
        title: "Commercial Industries",
        text: "Companies that aim to innovate and offer cutting-edge products to meet customer requests will ensure long-term growth and success.",
      },
    ],
  };

  const commonContent2 = {
    title: "Why work with a Product <br />Development Agency?",
    para: "Our product development team possesses the knowledge, resources, and experience your company needs for a successful product development journey.",
    img: ["what-are-benefits-product-dev.webp"],
    imageTitle: "Product Development Benefits",
    imageAlt: "",
    content: [
      {
        title: "Fresh Product Perspective",
        text: "Our Product development agency uses industry knowledge to obtain market insights, analyze trends, and provide new perspectives and solutions.",
      },
      {
        title: "Improved Product Quality",
        text: "To provide high-quality goods, our product development consultants do rigorous testing, iterate on designs, and follow industry standards.",
      },
      {
        title: "Cost-effective Solution",
        text: "Octet’s product developers utilize resources wisely to meet project objectives without exceeding budget restrictions.",
      },
      {
        title: "Increased Revenue",
        text: "Our product development experts create a unique product that fosters business growth by attracting customers and increasing market share.",
      },
    ],
  };
  const commonContent3 = {
    title: "Partner with the Best Product Development Company",
    linkText: ``,
    para: `We are a leading product design and development agency with an established track record and skilled specialists who brainstorm and build product designs for diverse industries and exceed user expectations.`,
    img: ["why-opt-for-product-dev.webp"],
    imageTitle: "Our Product Development Expertise",
    imageAlt: "",
    content: [
      {
        title: "Industrial Expertise ",
        text: "Our product development company has expertise in various sectors, so we understand their particular difficulties and can build new solutions accordingly.",
      },
      {
        title: "User-Centric Approach",
        text: "Octet's product developers prioritize user experience through rigorous research, testing, and iteration to provide a pleasurable product experience.",
      },
      {
        title: "Customised Solution",
        text: "Our product development agency recognizes that each firm has demands, challenges, and ambitions. As a result, we provide custom solutions tailored to individual businesses.",
      },
      {
        title: "Ongoing Product Support",
        text: "We believe in long-term collaborations, providing support in product development services throughout the product lifespan to ensure that your product remains optimal.",
      },
    ],
  };
  const commonContent4 = {
    title: "Octet’s Approach to Product Development Services",
    para: "As a leading product design and development agency, we create digital products with a progressive strategy. We offer creative solutions by combining a broad blend of product development expertise, including research, concept development, and more.",
    img: ["how-we-conduct-product-dev.webp"],
    imageTitle: "Process of Product Development",
    imageAlt: "",
    content: [
      {
        title: "1. Ideate and Plan",
        text: "Our product developers discuss numerous ideas, examine the shortlisted ideas from multiple perspectives, and then polish them to design the approach.",
      },
      {
        title: "3. Test and Monitor",
        text: "Our product development experts conduct usability tests to assess design robustness and user acceptability.",
      },
      {
        title: "2. Design and Develop",
        text: "Our product development team produces functional designs using cutting-edge development technology to accomplish greatness.",
      },
      {
        title: "4. Optimise and Iterate",
        text: "Our product developers regularly monitor and respond to user comments to enhance the product and maximize customer happiness.",
      },
    ],
  };
  const commonContent5 = {
    title: "Better ROI with the Best Product Development Company",
    para: "As the top product development agency, we make your product ideas a reality through marketing research, prototyping, iterating, and other processes to improve product quality, innovate, and deliver a fantastic user experience.",
    content: [
      {
        title: "Revenue Increased up to 10%",
        text: "Product development services address client concerns, resulting in improved user experience, recurring purchases, and increased market share.",
      },
      {
        title: "15% More Customer Retention",
        text: "Product development consultants provide continual improvements by responding to user feedback, improving digital products, and ultimately keeping customers.",
      },
      {
        title: "10%-20% Market Expansion",
        text: "A product development agency caters to various market segments and provides unique solutions that assist firms in gaining a larger market share.",
      },
      {
        title: "25% Revenue Diversification",
        text: "Best Product development company helps to extend into multiple market segments, reducing dependency on a single product and increasing revenue diversity.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them*.",
  };
  const cta = {
    title: "Need a Team to Grow Your Business?",
  };
  const cta2 = {
    title: "Ready To Launch Your Next Product?",
  };
  const cta3 = {
    title: "Let’s create meaningful <br />digital products together!",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "Uni Logix",
      desc: "A Logistics Insights Application",
      Industry: "Logistics",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp"],
      firstAlt: "",
      firstTitle: "Logistics Services List",
      secondAlt: "",
      secondTitle: "Order Details",
    },
    {
      title: "Elastic Sign",
      desc: "A Digital Signature Platform",
      Industry: "SaaS",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp"],
      firstAlt: "",
      firstTitle: "Automation Dashboard",
      secondAlt: "",
      secondTitle: "Test Run Results",
    },
  ];

  const Questions = [
    "How does your product development company blend innovation with market demand?",
    "Can your product development experts collaborate with our internal team during the development?",
    "Can your product development agency show some previous successful projects?",
    "Does Octet offer post-development support and product iteration?",
    "What is the duration of the product development process?",
    "What deliverables can I expect from your product development services?",
    "How do you measure the success of product development?",
  ];
  const clientsTitle =
    "Trusted By Client’s Worldwide";
  const faqDes = `As a product design and development agency,  we recognize that you may have more questions concerning our product development services. We hope to answer your questions and help you obtain clear information to make informed project decisions.`;

  const faqData = [
    {
      para: [
        `Our approach involves gaining a deep understanding of customer needs and market trends. We conduct thorough market research and identify market gaps, challenges, and opportunities. `,
        `After gaining these insights, our product developers bring fresh perspectives and innovative ideas to implement in your product.`,
        `Through our design and development process, we iterate, validate concepts, and gather user feedback, which results in blending innovative ideas into your products and gaining a competitive edge.`,
      ],
    },
    {
      para: [
        `At Octet, we value collaboration and work closely with your internal team during product development. We encourage open communication to ensure smooth interaction of ideas through regular meetings.`,
        `We aim to create a product that reflects your business identity, offers a seamless experience to your customers, and advances the market.`,
      ],
    },
    {
      para: [
        `Yes, you can check out our previous successful projects <a href="/projects/" target="_blank" rel="noreferrer">projects</a> here. However, we request that you <a href="/contact-us/" target="_blank" rel="noreferrer">contact us</a> for better assistance.`,
      ],
    },
    {
      para: [
        `Yes, as a product development agency, we understand that product development is not a one-time process and requires continued assistance.`,
        `We offer timely assistance, address your issues, and facilitate product iteration based on customer feedback to establish a long-term partnership with your organization.`,
      ],
    },
    {
      para: [
        `Our product development process varies depending on different factors, such as the complexity of your project, scope, and business requirements.`,
        `While the exact timeframe is difficult to specify, the process might take from a few weeks to several months.`
      ],
    },
    {
      para: [
        `Octet believes in transparency and provides a detailed project roadmap, product specifications, prototypes, wireframes, etc., for deployment.`,
        `We also provide regular progress reports, presentations, and feedback sessions to inform you about your product development process. `,
        `Our product development expertise delivers you a holistic project that fulfills your business goals and project requirements, resulting in high-quality digital products.`,
      ],
    },
    {
      para: [
        `We use specific qualitative and quantitative measures to measure the success of our product development process.`,
        `Firstly, we evaluate the product's performance with the pre-set goals by assessing user satisfaction, product usability, and engagement metrics.`,
        `We also check the KPIs, such as customer adoption rates, revenue growth, and market share, to analyze the product's impact on your business.`,
        `We also consider users' and stakeholders' feedback to ensure the project's success aligns with your business goals.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "How does your product development company blend innovation with market demand?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our approach involves gaining a deep understanding of customer needs and market trends. We conduct thorough market research and identify market gaps, challenges, and opportunities.
            After gaining these insights, our product developers bring fresh perspectives and innovative ideas to implement in your product.
            Through our design and development process, we iterate, validate concepts, and gather user feedback, which results in blending innovative ideas into your products and gaining a competitive edge."
                }
              },{
                "@type": "Question",
                "name": "Can your product development experts collaborate with our internal team during the development?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "At Octet, we value collaboration and work closely with your internal team during product development. We encourage open communication to ensure smooth interaction of ideas through regular meetings.
            We aim to create a product that reflects your business identity, offers a seamless experience to your customers, and advances the market."
                }
              },{
                "@type": "Question",
                "name": "Can your product development agency show some previous successful projects?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, you can check out our previous successful projects here. However, we request that you contact us for better assistance."
                }
              },{
                "@type": "Question",
                "name": "Does Octet offer post-development support and product iteration?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, as a product development agency, we understand that product development is not a one-time process and requires continued assistance.
            We offer timely assistance, address your issues, and facilitate product iteration based on customer feedback to establish a long-term partnership with your organization."
                }
              },{
                "@type": "Question",
                "name": "What is the duration of the product development process?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our product development process varies depending on different factors, such as the complexity of your project, scope, and business requirements.
            While the exact timeframe is difficult to specify, the process might take from a few weeks to several months."
                }
              },{
                "@type": "Question",
                "name": "What deliverables can I expect from your product development services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Octet believes in transparency and provides a detailed project roadmap, product specifications, prototypes, wireframes, etc., for deployment.
            We also provide regular progress reports, presentations, and feedback sessions to inform you about your product development process.
            Our product development expertise delivers you a holistic project that fulfills your business goals and project requirements, resulting in high-quality digital products."
                }
              },{
                "@type": "Question",
                "name": "How do you measure the success of product development?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We use specific qualitative and quantitative measures to measure the success of our product development process.
            Firstly, we evaluate the product's performance with the pre-set goals by assessing user satisfaction, product usability, and engagement metrics.
            We also check the KPIs, such as customer adoption rates, revenue growth, and market share, to analyze the product's impact on your business.
            We also consider users' and stakeholders' feedback to ensure the project's success aligns with your business goals."
                }
              }]
            }
            
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <WhatServiceDo data={whatServiceContent} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              From Concept to Market Success: <span className="h1-span">Our Case Studies</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials title={'Our Clients Take on Us'} />
          <Cta data={cta2} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title=" Product Development Company | Product Development Services"
    description="Your product development company; turning your ideas into reality by building innovative products & customizing UI UX services for an exceptional experience."
  />
);
